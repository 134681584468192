import { ApolloLink, from } from '@apollo/client';
import { message, notification } from 'antd';
import { createUploadLink } from 'apollo-upload-client';
import unfetch from 'isomorphic-unfetch';
import { onError } from '@apollo/client/link/error';
import { inMemoryAuth } from '../components/auth/in-memory-auth';

const links = [];
const authLink = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  if (inMemoryAuth.token) {
    operation.setContext({
      headers: {
        authorization: `JWT ${inMemoryAuth.token}`,
      },
    });
  }
  return forward(operation);
});

if (authLink) {
  links.push(authLink);
}

const errorLink = onError(({
  graphQLErrors,
  networkError,
}) => {
  if (graphQLErrors) {
    graphQLErrors.map(({
      message,
      locations,
      path,
    }) => {
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
      return notification.error({
        message: 'Server error',
        description: `Please try again or contact us. ${message}`,
      });
    });
  }

  if (networkError) {
    message.error(`${networkError.message}`);
  }
});

if (errorLink) {
  links.push(errorLink);
}

const apolloUploadLink = createUploadLink({
  uri: process.env.GATSBY_SALEOR_API_URL,
  fetch: unfetch,
});

if (apolloUploadLink) {
  links.push(apolloUploadLink);
}

const link = from(links);

export default link;
