// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-csr-js": () => import("./../../../src/pages/about/CSR.js" /* webpackChunkName: "component---src-pages-about-csr-js" */),
  "component---src-pages-about-faq-js": () => import("./../../../src/pages/about/FAQ.js" /* webpackChunkName: "component---src-pages-about-faq-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-about-privacy-js": () => import("./../../../src/pages/about/privacy.js" /* webpackChunkName: "component---src-pages-about-privacy-js" */),
  "component---src-pages-about-terms-js": () => import("./../../../src/pages/about/terms.js" /* webpackChunkName: "component---src-pages-about-terms-js" */),
  "component---src-pages-collections-js": () => import("./../../../src/pages/collections.js" /* webpackChunkName: "component---src-pages-collections-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-product-preview-index-js": () => import("./../../../src/pages/product/preview/index.js" /* webpackChunkName: "component---src-pages-product-preview-index-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-user-account-index-js": () => import("./../../../src/pages/user/account/index.js" /* webpackChunkName: "component---src-pages-user-account-index-js" */),
  "component---src-pages-user-auth-index-js": () => import("./../../../src/pages/user/auth/index.js" /* webpackChunkName: "component---src-pages-user-auth-index-js" */),
  "component---src-pages-user-favourites-index-js": () => import("./../../../src/pages/user/favourites/index.js" /* webpackChunkName: "component---src-pages-user-favourites-index-js" */),
  "component---src-templates-collection-index-js": () => import("./../../../src/templates/collection/index.js" /* webpackChunkName: "component---src-templates-collection-index-js" */),
  "component---src-templates-product-index-js": () => import("./../../../src/templates/product/index.js" /* webpackChunkName: "component---src-templates-product-index-js" */)
}

