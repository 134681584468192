import { InMemoryCache, makeVar } from '@apollo/client';

export const actWishId = makeVar(undefined);
export const actWishVariants = makeVar([]);

const cache = new InMemoryCache({
  typePolicies: {
    Wishlist: {
      fields: {
        isActive: {
          read(_, { readField }) {
            const wishlistId = readField('id');
            return actWishId() === wishlistId;
          },
        },
      },
    },
  },
});

export default cache;
