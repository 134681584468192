export const inMemoryAuth = {
  token: undefined,
  refreshToken: undefined,
  csrfToken: undefined,
  user: {
    id: undefined,
    email: undefined,
    isStaff: undefined,
    userPermissions: undefined,
  },
};
